import { IReflektionJSON } from '~/tracking/vendor-events/reflektion/Interfaces/IReflektionEvents.tracking';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';
import { sanitize } from '~/util/sanitizeString';

export class ReflektionPresentEventTrackingModel {
	title: string;

	constructor(title: string) {
		this.title = title;
	}

	get trackingJson(): IReflektionJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		return {
			event: 'rfkpresent',
			vendor: {
				rfkCarousel: sanitize(this.title),
				rfkPresent: 1,
			},
			content: vendorEventTrackingModel.pageID,
			page: {
				id: vendorEventTrackingModel.pageID,
			},
		};
	}
}
