import {
	model, Model, prop, idProp, modelFlow, _async, _await, modelAction,
} from 'mobx-keystone';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { observable } from 'mobx';

import styles from '~/favorites/create-new-list/Components/create-new-list.module.scss';

import { modelNamespace } from '~/util/modelNamespace';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { IFavorites, IFavoritesAddListData, IFavoritesCreateListModalData } from '~/favorites/Types/Favorites.interface';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { LinkEventStore, LinkEventStoreFactory } from '~/tracking/link-event/Stores/LinkEvent.store';

interface ICreateFavoritesListFormModel {
	listName: string
}

export interface IPickupLocation {
	name: string
}

@model(`${modelNamespace.FAVORITES}/CreateNewListStore`)
export class CreateNewFavoritesListStore extends Model({
	id: idProp,
	createNewListAPI: prop<string>('/api/favorites/add-list'),
	shouldCreateListAndMoveItem: prop<boolean>(false),
}) {
	@observable hasSaveError: boolean | undefined;

	@observable isLoading: boolean | undefined;

	@observable.ref form: any;

	@observable.ref featureTogglesModel: FeatureTogglesModel | undefined;

	@observable.ref openModalResolve: ((value?: (IFavoritesCreateListModalData | PromiseLike<IFavoritesCreateListModalData>)) => void) | undefined = undefined;

	@observable.ref openModalReject: ((reason?: any) => void) | undefined = undefined;

	formModel: ICreateFavoritesListFormModel | undefined;

	linkEventStore: LinkEventStore | undefined;

	onInit() {
		this.hasSaveError = false;
		this.isLoading = false;
		this.setupForm();
	}

	@modelAction
	setupForm() {
		this.formModel = {
			listName: '',
		};

		this.form = new FormBuilder(this.formModel, this.formSettings);
	}

	@modelFlow
	promiseHandler = _async(function* (this: CreateNewFavoritesListStore, promise: Promise<any>) {
		try {
			const addListResponse: AxiosResponse<IFavoritesAddListData> = yield* _await(promise);

			if (!addListResponse.data) {
				return;
			}

			const favoritesListData = yield* _await(axios.request<IFavorites>({
				method: 'get',
				url: addListResponse.data._links?.favoritesListSummaries.href,
			}));

			if (typeof this.openModalResolve === 'function') {
				this.openModalResolve({
					...favoritesListData.data,
					favoritesList: addListResponse.data.favoritesList,
				});
			}
		} catch (error: any) {
			console.error(error);
			this.hasSaveError = true;
			this.isLoading = false;
			if (typeof this.openModalReject === 'function') {
				this.openModalReject(error);
			}
		}
	});

	submitHandler(form: any) {
		if (this.featureTogglesModel) {
			if (!this.linkEventStore) {
				this.linkEventStore = LinkEventStoreFactory.create(this.featureTogglesModel);
			}
			this.linkEventStore.trackLinkEvent({
				trLinkEventName: 'create list',
				trLinkEventCompType: 'modal',
				trLinkEventCompName: 'create new list',
				trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
			});
		}

		form.plugins.formValidator.validateForm();
		if (!form.plugins.formValidator.hasErrors) {
			this.isLoading = true;
			return axios.post(this.createNewListAPI, {
				newListName: this.form.model.listName,
			});
		}
		return Promise.reject('form has errors');
	}

	cancel() {
		if (typeof this.openModalResolve !== 'function') {
			return;
		}
		this.openModalResolve();
	}

	get formSettings() {
		const self = this;

		return {
			id: 'create-new-favorites-list-form',
			reactProps: {
				method: 'put',
				className: 'formz create-new-favorites-list-form',
			},
			settings: {
				plugins: [
					new ValidateAndAjaxSubmitPlugin({
						ajaxSubmit: {
							promiseHandler: (promise: AxiosPromise) => {
								return self.promiseHandler(promise);
							},
							submitHandler: (form: any) => {
								return self.submitHandler(form);
							},
						},
					}),
				],
			},
			fields: {
				listName: {
					label: {
						reactProps: {
							children: 'List Name',
						},
					},
					control: {
						reactProps: {
							type: 'text',
							maxLength: 40,
							className: styles['list-name'],
						},
					},
					settings: {
						validationConstraints: {
							presence: {
								message: '^Enter a name for the list.',
							},
						},
					},
				},
			},
		};
	}

	get submitButtonLabel(): string {
		return this.shouldCreateListAndMoveItem ? 'Create List & Move Item' : 'Create List';
	}
}
