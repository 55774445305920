import {
	idProp,
	Model,
	model, prop,
} from 'mobx-keystone';

import type { IAnalyticsProduct } from '~/favorites/Types/Favorites.interface';
import { FavoritesProductTrackingModel } from '~/favorites/Models/FavoritesProduct.tracking.model';

import { modelNamespace } from '~/util/modelNamespace';

const FavoritesActionType = {
	ADD: 'add',
	REMOVE: 'remove',
} as const;

export enum FavoritesEvent {
	REMOVE_FROM_FAVORITES,
	ADD_TO_FAVORITES,
}

type FavoritesEventMap = {
    [key in FavoritesEvent]: {
        eventName: string
        action: typeof FavoritesActionType[keyof typeof FavoritesActionType]
    }
};
const FavoritesEvents: FavoritesEventMap = {
	[FavoritesEvent.REMOVE_FROM_FAVORITES]: {
		eventName: 'removefavorite',
		action: FavoritesActionType.REMOVE,
	},
	[FavoritesEvent.ADD_TO_FAVORITES]: {
		eventName: 'addfavorite',
		action: FavoritesActionType.ADD,
	},
};

export type FavoritesEventTypes = keyof typeof FavoritesEvent;

@model(`${modelNamespace.FAVORITES}/FavoritesTrackingEvent`)
export class FavoritesTrackingEventModel extends Model({
	id: idProp,
	favoritesListId: prop<string>(),
	favoritesEventType: prop<FavoritesEvent>(),
	analyticsProducts: prop<IAnalyticsProduct[]>(),
}) {
	get sumPrice() {
		return this.analyticsProducts.reduce((sum, { product }) => sum + product.unitPrice * product.quantity, 0);
	}

	get trackingProducts() {
		const trackingProducts = this.analyticsProducts.map((analyticsProduct) => {
			const eventProduct = new FavoritesProductTrackingModel(
				analyticsProduct.product,
			);

			return eventProduct;
		}).flat();

		return trackingProducts;
	}

	get trackingVendor() {
		const trackingVendor: {reflektion: object[], variantIds: string[]} = {
			reflektion: [],
			variantIds: [],
		};

		this.analyticsProducts.forEach((analyticsProduct) => {
			trackingVendor.reflektion.push({ ...analyticsProduct.vendor.reflektion });
			trackingVendor.variantIds.push(analyticsProduct.vendor.variantId);
		});
		return trackingVendor;
	}

	get trackingJson() {
		const favoritesEvent = FavoritesEvents[this.favoritesEventType];
		return {
			event: favoritesEvent.eventName,
			favorite: {
				id: this.favoritesListId,
				[favoritesEvent.action]: this.sumPrice,
			},
			product: this.trackingProducts.map((product) => {
				return product.trackingJson;
			}),
			vendor: this.trackingVendor,
		};
	}

	get comparatorString() {
		return `[${this.analyticsProducts.map(analyticsProduct => analyticsProduct.product.comparatorString).join(',')}]`;
	}

	equalTo(comparatorString: string) {
		return this.comparatorString === comparatorString;
	}
}
