import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';
import { CreateNewFavoritesListStore } from '~/favorites/create-new-list/Stores/CreateNewList.store';
import { MagicSpinner } from '~/components/MagicSpinner';

export const CreateNewFavoritesList = observer((props: { store: CreateNewFavoritesListStore }) => {
	const {
		store,
		store: {
			form: {
				reactProps: formReactProps = null,
				fields: {
					listName = undefined,
				} = {},
			} = {},
			isLoading = false,
			hasSaveError = false,
		}
	} = props;

	if (isLoading) {
		return (
			<div className="tw-h-40">
				<MagicSpinner
					// @ts-ignore
					isLoading={true}
					parentSettings={{ width: '100%', height: '100%' }}
					parentPosition="relative"
					minHeight="160px"
				/>
			</div>
		);
	}

	return (
		<form {...formReactProps}>
			<Field
				field={listName}
			/>

			<div className="modal-buttons ButtonGroup tw-mt-4 tw-mb-7">
				<input
					type="submit"
					data-qa="create-new-favorites-list-save-button"
					className="Button"
					value={store.submitButtonLabel}
				/>
				<button
					type="button"
					data-qa="create-new-favorites-list-cancel-button"
					data-ignore-blur-validation
					className="ButtonAnchor"
					onClick={(event) => {
						event.preventDefault();
						store.cancel();
					}}
				>Cancel</button>
			</div>
			{
				hasSaveError &&
				<div className="error tw-mt-4 tw-text-red">
					Unable to save changes. Please try again.
				</div>
			}
		</form>
	);
});
