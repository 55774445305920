import {
	IReflektionAppearJSON, IReflektionJSON, IReflektionSearchAppearJSON, IReflektionSearchProductClickedJSON, IReflektionWidgetClickJSON,
} from '~/tracking/vendor-events/reflektion/Interfaces/IReflektionEvents.tracking';
import { redirect } from '~/util/redirect';
import { ReflektionAppearEventTrackingModel } from '~/tracking/vendor-events/reflektion/Models/ReflektionAppearEvent.tracking.model';
import { ReflektionPresentEventTrackingModel } from '~/tracking/vendor-events/reflektion/Models/ReflektionPresentEvent.tracking.model';
import { ReflektionProductClickedTrackingModel } from '~/tracking/vendor-events/reflektion/Models/ReflektionProductClicked.tracking.model';
import { ReflektionSearchAppearEventTrackingModel } from '~/tracking/vendor-events/reflektion/Models/ReflektionSearchAppearEvent.tracking.model';
import { ReflektionSearchProductClickedTrackingModel } from '~/tracking/vendor-events/reflektion/Models/ReflektionSearchProductClicked.tracking.model';
import { IProductContentResult } from '~/search/Types/SearchPage.type';

export class ReflektionEventsTrackingStore {
	tsWindow = global as any;

	previouslyTrackedSkus: string | null;

	featureTogglesModel;

	constructor(featureTogglesModel: any = {}) {
		this.previouslyTrackedSkus = '';
		this.featureTogglesModel = featureTogglesModel;
	}

	trackReflektionAppear(rfkWidgetId: string | null = null) {
		if (rfkWidgetId) {
			const reflektionAppearTrackingModel = new ReflektionAppearEventTrackingModel(rfkWidgetId);
			this.pushToEventDataLayer(reflektionAppearTrackingModel.trackingJson);
		}
	}

	trackReflektionPresent(title: string) {
		if (!this.tsWindow.eventDataLayer) {
			return;
		}

		const reflektionPresentTrackingModel = new ReflektionPresentEventTrackingModel(title);
		this.pushToEventDataLayer(reflektionPresentTrackingModel.trackingJson);
	}

	trackReflektionProductClicked(widgetId: string, recoItemModel: { url: string }, disableRfkTracking?: boolean) {
		if (this.tsWindow.eventDataLayer && !disableRfkTracking) {
			const reflektionProductClickedTrackingModel = new ReflektionProductClickedTrackingModel(widgetId, recoItemModel);
			this.pushToEventDataLayer(reflektionProductClickedTrackingModel.trackingJson);
		}

		// still navigate to the link location, even if we didn't track the click with rfk
		if (!window.location.search.includes('rfkNoRedirect')) {
			setTimeout(() => {
				if (recoItemModel.url) {
					redirect(recoItemModel.url);
				} else {
					window.location.reload();
				}
			}, 250);
		}
	}

	trackReflektionSearchAppear(): void {
		const reflektionSearchAppearTrackingModel = new ReflektionSearchAppearEventTrackingModel();

		this.pushToEventDataLayer(reflektionSearchAppearTrackingModel.trackingJson);
	}

	trackReflektionSearchProductClick(index: number, product: IProductContentResult): void {
		const reflektionSearchProductClickedTrackingModel = new ReflektionSearchProductClickedTrackingModel(index, product);

		this.pushToEventDataLayer(reflektionSearchProductClickedTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json: IReflektionAppearJSON | IReflektionJSON | IReflektionWidgetClickJSON | IReflektionSearchAppearJSON | IReflektionSearchProductClickedJSON) {
		this.tsWindow.eventDataLayer.push(json);
	}
}
