import {
	model, Model, idProp,
} from 'mobx-keystone';

import type { IAnalyticsProduct, IAnalyticsCart } from '~/favorites/Types/Favorites.interface';
import { modelNamespace } from '~/util/modelNamespace';
import { FavoritesTrackingEventModel, FavoritesEvent } from '~/favorites/Models/FavoritesEvent.tracking.model';
import { isOnServer } from '~/global/global.constants';
import { AddProductToCartEvent } from '~/product/common/Models/AddToCartEvent.tracking';
import { TrackedCart } from '~/product/common/Models/TrackedCart.tracking.model';
import { TrackedProduct } from '~/product/common/Models/TrackedProduct.tracking';

@model(`${modelNamespace.FAVORITES}/TrackingEventsStore`)
export class FavoritesTrackingEventsStore extends Model({
	id: idProp,
}) {
	previousTrackingJsonString: string | undefined;

	onInit() {
		this.previousTrackingJsonString = '';
	}

	addToFavorites(favoritesListIds: string[], analyticsProducts: IAnalyticsProduct[]) {
		favoritesListIds.forEach((favoritesListId) => {
			const addToFavoritesEvent = new FavoritesTrackingEventModel({
				favoritesListId,
				favoritesEventType: FavoritesEvent.ADD_TO_FAVORITES,
				analyticsProducts,
			});
			this.pushToEventDataLayer(addToFavoritesEvent.trackingJson);
		});
	}

	removeFromFavorites(favoritesListId: string, analyticsProducts: IAnalyticsProduct[]) {
		const removeFromFavoritesEvent = new FavoritesTrackingEventModel({
			favoritesListId,
			favoritesEventType: FavoritesEvent.REMOVE_FROM_FAVORITES,
			analyticsProducts,
		});
		this.pushToEventDataLayer(removeFromFavoritesEvent.trackingJson);
	}

	moveToCart(favoritesListIds: string, analyticsProducts: IAnalyticsProduct[], analyticsCart: IAnalyticsCart) {
		this.removeFromFavorites(favoritesListIds, analyticsProducts);
		this.addToCart(analyticsProducts, analyticsCart);
	}

	addToCart(analyticsProducts: IAnalyticsProduct[], analyticsCart: IAnalyticsCart) {
		const reflektionData = (analyticsProducts).map((cartProduct) => {
			const {
				vendor: {
					reflektion,
				} = {},
			} = cartProduct;
			return reflektion;
		}).flat();
		const variantIdsData = (analyticsProducts).map((cartProduct) => {
			const {
				vendor: {
					variantId = '',
				} = {},
			} = cartProduct;
			return variantId;
		}).flat();
		const trackedProducts = (analyticsProducts).map((analyticsProduct) => {
			const {
				product,
			} = analyticsProduct;
			return new TrackedProduct(product, { reflektion: reflektionData, variantIds: variantIdsData });
		});
		const addToCartData = { cart: { analytics: { ...analyticsCart } } };
		const trackedCart = new TrackedCart(addToCartData, trackedProducts);
		const event = new AddProductToCartEvent(addToCartData, trackedProducts, trackedCart, reflektionData, variantIdsData);

		this.pushToEventDataLayer(event.trackingJson);
	}

	pushToEventDataLayer(json: unknown) {
		const tsWindow = window as any;
		if (!isOnServer && tsWindow?.eventDataLayer && this.previousTrackingJsonString !== JSON.stringify(json)) {
			this.previousTrackingJsonString = JSON.stringify(json);
			tsWindow.eventDataLayer.push(json);
		}
	}
}
