import axios from 'axios';
import {
	autorun, action, when, makeObservable, observable,
} from 'mobx';

import {
	recommendationsApiUrl, isCypress, isEngage, isOnServer,
} from '~/global/global.constants';
import { MagicRecosRfkModel } from '~/components/magic-recos-rfk/Models/MagicRecosRfk.model';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';

export class MagicRecosRfkStore {
	accessTokenRetryCount = 0;

	maxAccessTokenRetry = 3;

	model;

	recommendationsAccessTokenLink;

	featureTogglesModel;

	useStubData;

	context;

	constructor(widgetId, uri, context, options) {
		makeObservable(this, {
			fetchRecos: action.bound,
			fetchRecosErrorHandler: action.bound,
			getNewAccessToken: action.bound,
			trackVendorPresent: action.bound,
			model: observable,
		});

		this.model = new MagicRecosRfkModel(widgetId, uri, options, context);
		this.context = context;
		this.useStubData = context.featureTogglesModel.isOn('SPOOF_RECOS');
		this.reflektionEventsTrackingStore = new ReflektionEventsTrackingStore();
	}

	fetchRecos() {
		const axiosInstance = axios.create({
			headers: {
				common: {
					Accept: 'application/json',
				},
				post: {
					Authorization: `Bearer ${this.model.accessToken}`,
				},
			},
			withCredentials: false,
		});

		delete axiosInstance.defaults.headers.common['X-Requested-With'];
		delete axiosInstance.defaults.headers.common['True-Client-IP'];
		delete axiosInstance.defaults.headers.common['X-Bypass-Ratelimiting'];
		delete axiosInstance.defaults.headers.common['X-Forwarded-For'];

		const data = {
			widget: {
				rfkid: this.model.widgetId,
			},
			context: {
				page: {
					...(this.model.sku && {
						sku: this.model.sku,
					}),
					...(this.model.name && {
						name: this.model.name,
					}),
					uri: this.model.uri,
				},
				...(this.model.uuid && {
					user: {
						uuid: this.model.uuid,
					},
				}),
			},
			n_item: this.model.maxItems,
			content: {
				product: {
					field: {
						value: this.model.contentFields,
					},
				},
			},
		};

		return axiosInstance.post(recommendationsApiUrl, { data }).then((resp) => {
			if (this.featureTogglesModel.isOn('SPOOF_RECOS')) {
				this.model.setRecosData(this.model.stubData);
				this.model.setHasRecos(true);
			} else {
				this.model.rid = resp.data.rid;
				this.model.variationId = resp.data.widget.variation_id;
				this.model.setRecosData(resp.data);
				if (resp.data.total_item) {
					this.model.setHasRecos(true);
					this.trackVendorPresent();
				}
			}
			return resp;
		});
	}

	fetchRecosErrorHandler(error) {
		const status = error?.response?.status;
		const url = error?.response?.config?.url;

		if (status !== 401) {
			console.error(`Failed to retrieve recommendations with ${url}`);
			if (isOnServer || !isCypress) {
				return;
			}
		}
		if (this.accessTokenRetryCount < this.maxAccessTokenRetry) {
			this.getNewAccessToken();
		} else {
			console.error(`Failed to authenticate with ${url} after ${this.accessTokenRetryCount} retries`);
		}
	}

	getNewAccessToken() {
		this.accessTokenRetryCount++;
		return axios.get(this.recommendationsAccessTokenLink).then((resp) => {
			this.model.accessToken = resp?.data?.accessToken;
			return resp;
		});
	}

	trackVendorPresent() {
		if (!isOnServer && !isEngage) {
			this.reflektionEventsTrackingStore.trackReflektionPresent(this.model.title);
		}
	}
}

export const MagicRecosRfkStoreFactory = {
	create(widgetId, uri, context, options) {
		if (!widgetId) {
			throw new Error('Unable to create MagicRecosRfkStore. Missing required argument: widgetId.');
		}
		// if (!uri) {
		// 	throw new Error('Unable to create MagicRecosRfkStore. Missing required argument: uri.');
		// }

		const store = new MagicRecosRfkStore(widgetId, uri, context, options);

		store.featureTogglesModel = context.featureTogglesModel;

		// global.recos = store;
		// Wait for globals token response
		store.recommendationsAccessTokenLink = context.globalStaticModel.recommendationsAccessTokenLink;
		when(() => context.globalDynamicModel.recommendationsAccessToken, () => {
			store.model.accessToken = context.globalDynamicModel.recommendationsAccessToken;
		});

		// Fetch fresh recos if widgetId, accessToken, or skus change.
		autorun(() => {
			if (store.model.widgetId && store.model.accessToken && Array.isArray(store.model.sku)) {
				let attempts = 0;

				// wait for ReflektionScripts.tsx to set a uid (deferred loaded script tag), so that recently viewed will work correctly
				const rfkUidInterval = setInterval(() => {
					if (global.rfk?.uid?.() || attempts >= 5) {
						clearInterval(rfkUidInterval);

						store.fetchRecos(store.useStubData).catch(store.fetchRecosErrorHandler);
					}

					attempts++;
				}, 250);
			}
		});

		return store;
	},
};
