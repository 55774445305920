import {
	model, Model, prop, idProp,
} from 'mobx-keystone';

import { modelNamespace } from '~/util/modelNamespace';
import { addToUrl } from '~/util/addToUrl';

@model(`${modelNamespace.UTIL}/ImageModel`)
export class ImageModel extends Model({
	id: idProp,
	imagePath: prop<string>(),
	width: prop<number | undefined>(),
	height: prop<number | undefined>(),
	mediumWidth: prop<number | undefined>(),
	mediumHeight: prop<number | undefined>(),
}) {
	get imageSrc() {
		if (this.width && this.height) {
			return this.createImageSourceWithSize(this.width, this.height);
		}
		if (this.width) {
			return this.createImageSourceWithWidth(this.width);
		}
		if (this.height) {
			return this.createImageSourceWithHeight(this.height);
		}
		return this.imagePath;
	}

	get imageSrcSet() {
		if (this.width && this.height) {
			return this.createImageSourceSetWithSize(this.width, this.height);
		}
		if (this.width) {
			return this.createImageSourceSetWithWidth(this.width);
		}
		if (this.height) {
			return this.createImageSourceSetWithHeight(this.height);
		}
		return this.imagePath;
	}

	get mediumImageSrcSet() {
		if (this.mediumWidth && this.mediumHeight) {
			return this.createImageSourceSetWithSize(this.mediumWidth, this.mediumHeight);
		}
		if (this.mediumWidth) {
			return this.createImageSourceSetWithWidth(this.mediumWidth);
		}
		if (this.mediumHeight) {
			return this.createImageSourceSetWithHeight(this.mediumHeight);
		}
		return this.imagePath;
	}

	get mediumImageSrc() {
		if (this.mediumWidth && this.mediumHeight) {
			return this.createImageSourceWithSize(this.mediumWidth, this.mediumHeight);
		}
		if (this.mediumWidth) {
			return this.createImageSourceWithWidth(this.mediumWidth);
		}
		if (this.mediumHeight) {
			return this.createImageSourceWithHeight(this.mediumHeight);
		}
		return this.imagePath;
	}

	createImageSourceWithWidth(width: number) {
		return addToUrl(this.imagePath, `wid=${width}`);
	}

	createImageSourceWithHeight(height: number) {
		return addToUrl(this.imagePath, `hei=${height}`);
	}

	createImageSourceWithSize(width: number, height: number) {
		return addToUrl(this.imagePath, `wid=${width}&hei=${height}`);
	}

	createImageSourceSetWithWidth(width: number) {
		return [
			this.createImageSourceWithWidth(width),
			addToUrl(this.imagePath, `wid=${width * 2} 2x`),
		].join(', ');
	}

	createImageSourceSetWithHeight(height: number) {
		return [
			this.createImageSourceWithHeight(height),
			addToUrl(this.imagePath, `hei=${height * 2} 2x`),
		].join(', ');
	}

	createImageSourceSetWithSize(width: number, height: number) {
		return [
			this.createImageSourceWithSize(width, height),
			addToUrl(this.imagePath, `wid=${width * 2}&hei=${height * 2} 2x`),
		].join(', ');
	}
}
