export class VendorEventTrackingModel {
	tsWindow = global as any || undefined;

	get pageID(): string {
		const parsedUrl = new URL(this.tsWindow.location);
		const { pathname } = parsedUrl;
		const normalizedPathname = pathname.replace(/^\/m/, '');

		if (normalizedPathname === '/' || normalizedPathname.length === 0) {
			return 'homepage';
		}
		return normalizedPathname.replace(/\/(?!$)/g, ':').replace(/\/|(\..+$)/g, '');
	}
}
