import { IReflektionWidgetClickJSON } from '~/tracking/vendor-events/reflektion/Interfaces/IReflektionEvents.tracking';

export class ReflektionProductClickedTrackingModel {
	index: number
	
	rfkWidgetId: string

	sku: string

	constructor(rfkWidgetId: string, recoItemModel: any) {
		this.rfkWidgetId = rfkWidgetId;
		this.index = recoItemModel.index;
		this.sku = recoItemModel.sku;
	}

	get trackingJson(): IReflektionWidgetClickJSON {
		return {
			event: 'recowidgetclick',
			index: this.index,
			rfkWidgetId: this.rfkWidgetId,
			sku: this.sku,
		};
	}
}
