import type { IProductAnalytics } from '~/favorites/Types/Favorites.interface';
import { ifEmptyUsePlaceholderText } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';
import { s7ImagePath } from '~/global/global.constants';

export class FavoritesProductTrackingModel {
	product: IProductAnalytics;

	constructor(product: IProductAnalytics) {
		this.product = product;
	}

	formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
	});

	get trackedArticleNumber() {
		return this.product.articleNumber;
	}

	get trackedName() {
		return ifEmptyUsePlaceholderText(this.product.name && sanitize(this.product.name));
	}

	get imagePathMinusBaseUrl() {
		const imageUrl = this.product.imageUrl || '';
		return imageUrl.replace(`${s7ImagePath}/`, '');
	}

	get trackedThumb() {
		return ifEmptyUsePlaceholderText(this.imagePathMinusBaseUrl.substring(0, 255));
	}

	get trackedThumb2() {
		return ifEmptyUsePlaceholderText(this.imagePathMinusBaseUrl.substring(255, 255));
	}

	get urlString() {
		return ifEmptyUsePlaceholderText(this.product.productUrl);
	}

	get trackedUrl() {
		return this.urlString.substring(0, 255);
	}

	get trackedUrl2() {
		return ifEmptyUsePlaceholderText(this.urlString.substring(255, 255));
	}

	get trackedWebCategory() {
		return ifEmptyUsePlaceholderText(this.product.urlCategory);
	}

	get trackedWebICP() {
		return ifEmptyUsePlaceholderText(this.product.urlICP);
	}

	get trackedWebSubcategory() {
		return ifEmptyUsePlaceholderText(this.product.urlSubcategory);
	}

	get trackedQuantity() {
		return this.product.quantity;
	}

	get trackedUnitPrice() {
		return this.product.unitPrice;
	}

	get formattedUnitPrice() {
		return this.formatter.format(this.product.unitPrice).replace(/\$|,/g, '');
	}

	get trackedStockedStatus() {
		return ifEmptyUsePlaceholderText(this.product.stockedStatus);
	}

	get trackedWebMessage() {
		return ifEmptyUsePlaceholderText(this.product.webMessage || this.product.productStatus);
	}

	get unattachedOttoman(): boolean {
		return this.product.unattachedOttoman;
	}

	get trackingJson() {
		return {
			id: this.trackedArticleNumber,
			name: this.trackedName,
			quantity: this.trackedQuantity,
			stockedStatus: this.trackedStockedStatus,
			thumb: this.trackedThumb,
			thumb2: this.trackedThumb2,
			...(this.unattachedOttoman && { ottomanAttachment: 'unattached' }),
			url: this.trackedUrl,
			url2: this.trackedUrl2,
			uPrice: this.formattedUnitPrice,
			webCat: this.trackedWebCategory,
			webICP: this.trackedWebICP,
			webMessage: this.trackedWebMessage,
			webSub: this.trackedWebSubcategory,
		};
	}

	get comparatorString() {
		return JSON.stringify(this.trackingJson);
	}

	equalTo(comparatorString: string) {
		return this.comparatorString === comparatorString;
	}
}
