import { IReflektionSearchProductClickedJSON } from '~/tracking/vendor-events/reflektion/Interfaces/IReflektionEvents.tracking';
import { IProductContentResult } from '~/search/Types/SearchPage.type';

export class ReflektionSearchProductClickedTrackingModel {
	index: number;

	product: IProductContentResult;

	constructor(index: number, product: IProductContentResult) {
		this.index = index;
		this.product = product;
	}

	get trackingJson(): IReflektionSearchProductClickedJSON {
		return {
			event: 'searchwidgetclick',
			index: this.index,
			product: this.product,
		};
	}
}
