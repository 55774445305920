import { FavoritesListSummary } from '~/favorites/Models/FavoritesListSummary.model';
import { IFavorites } from '~/favorites/Types/Favorites.interface';
import { ImageModel } from '~/util/images/Image.model';

export const createFavoriteSummaries = (data: IFavorites) => {
	const lists: FavoritesListSummary[] = data.favoritesListSummaries.map((summary) => {
		const {
			id,
			name,
			defaultList,
			sortOrder,
			hasDesignPresentation = false,
			imageUrl,
			itemCount,
			_links: {
				favoritesList: {
					href: detailedInfoAPI = '',
				} = {},
			} = {},
			subtotal,
		} = summary;
		return new FavoritesListSummary({
			id,
			name,
			defaultList,
			sortOrder,
			hasDesignPresentation,
			...(imageUrl) && {
				image: new ImageModel({
					imagePath: imageUrl,
					width: 114,
					height: 100,
					mediumWidth: 354,
					mediumHeight: 200,
				}),
			},
			itemCount,
			detailedInfoAPI,
			subtotal,
		});
	});

	return lists;
};
