import { IReflektionAppearJSON } from '~/tracking/vendor-events/reflektion/Interfaces/IReflektionEvents.tracking';

export class ReflektionAppearEventTrackingModel {
	rfkWidgetId: string

	constructor(rfkWidgetId: string) {
		this.rfkWidgetId = rfkWidgetId;
	}

	get trackingJson(): IReflektionAppearJSON {
		return {
			event: 'recowidgetappear',
			rfkWidgetId: this.rfkWidgetId,
		};
	}
}
